<template>
  <div>
    <TitleMore
      :title="['我要登记']"
      :isMore="false"
    ></TitleMore>
    <div
      class="baseCard"
      style="margin-top: 20px;display: flex;flex-direction: column;"
    >
      <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
line-height: 48px;text-align:center;margin-top:20px">{{detailData.title}}</p>
      <TitleLine width="400px"></TitleLine>
      <div
        class="detailContent"
        v-html="detailData.text || detailData.units_info"
      ></div>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore.vue";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  components: {
    TitleMore,
    TitleLine,
  },
  data() {
    return {
      detailData: {},
    };
  },

  mounted() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      apiUrls.getStatistics({ node: "cell_wydj" }).then((res) => {
		  console.log(res.results.data)
        this.detailData = res.results.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>